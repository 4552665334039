/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import { Badge } from '@labourhub/labour-hub-ds'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'
import moment from 'moment'

import { timeToAESTWithSeconds } from 'utils'

export const AssessmentResponsesListColumn = (
  setSelectedResponse: any,
  setIsDownlaodAssessmentReport: any,
) => {
  const COLUMNS = [
    {
      Header: 'ASSESSMENT NAME',
      accessor: 'AssessmentName',
      forSorting: 'AssessmentName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[175px] lg:w[200px] w-[200px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className=''>
            <p
              className=' cursor-pointer flex items-center gap-0.5'
              data-tip={original?.assessmentName}
              data-for={original?.assessmentId}>
              <ReactTooltip
                place='right'
                id={original?.assessmentId}
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                delayShow={800}
              />
              <span className='truncate max-w-[150px]'>
                {original?.assessmentName}
              </span>
            </p>
          </div>
        )
      },
    },
    {
      Header: 'TYPE',
      accessor: 'difficultyType',
      forSorting: 'difficultyType',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='flex items-center'>
            <img
              className='w-[15px] relative -top-0.5'
              src={difficultyImages[original.difficultyLevel]}
              alt={original.difficultyLevel}
            />
            <span className='ml-1 text-extra-small'>
              {original.difficultyLevel}
            </span>
          </div>
        )
      },
    },
    {
      Header: 'SENT BY',
      accessor: 'SentBy',
      forSorting: 'SentBy',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='truncate'
            data-tip={original?.sentBy}
            data-for={original?.assessmentId}>
            <ReactTooltip
              place='right'
              id={original?.assessmentId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {original?.sentBy}
          </div>
        )
      },
    },
    {
      Header: 'EMAIL',
      accessor: 'Response',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'AWAITING_RESPONSE' ? 'gray' : 'green'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {original?.status === 'AWAITING_RESPONSE' ? 'Sent' : 'Opened'}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'SENT ON',
      accessor: 'SentOn',
      forSorting: 'SentOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateStringWithSeconds = timeToAESTWithSeconds(
          original?.sentOn,
        )
        const createdDateString = moment(original?.sentOn).format('DD/MM/yyyy')
        return (
          <div
            data-tip={createdDateStringWithSeconds}
            data-for={original?.assessmentId}>
            <ReactTooltip
              place='right'
              id={original?.assessmentId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {createdDateString}
          </div>
        )
      },
    },
    // {
    //   Header: 'COMPLETED ON',
    //   accessor: 'CompletedOn',
    //   forSorting: 'CompletedOn',
    //   isSortable: true,
    //   hasFilter: true,
    //   isVisible: true,
    //   commonClassName: 'md:w-[150px] lg:w[170px] w-[170px] pl-6',
    //   headerClassName: 'flex items-center',
    //   cellClassName: 'flex items-center',
    //   Cell: (props: any) => {
    //     const {
    //       row: { original },
    //     } = props || {}
    //     const updatedDateStringWithSecond =
    //       original?.completedOn !== null
    //         ? timeToAESTWithSeconds(original?.completedOn)
    //         : '-'
    //     const updatedDateString =
    //       original?.completedOn !== null
    //         ? moment(original?.completedOn).format('DD/MM/yyyy')
    //         : '-'
    //     return (
    //       <div
    //         data-tip={updatedDateStringWithSecond}
    //         data-for={original?.assessmentId}>
    //         <ReactTooltip
    //           place='right'
    //           id={original?.assessmentId}
    //           effect='solid'
    //           textColor='#FFFFFF'
    //           backgroundColor='#4B5563'
    //           delayShow={800}
    //         />
    //         {updatedDateString}
    //       </div>
    //     )
    //   },
    // },
    {
      Header: 'RESPONSE',
      accessor: 'Status',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'AWAITING_RESPONSE'
            ? 'yellow'
            : original?.status === 'REQUEST_DECLINED'
            ? 'red'
            : original?.status === 'RESPONSE_RECEVIED'
            ? 'green'
            : original?.status === 'RESPONSE_IN_REVIEW'
            ? 'yellow'
            : original?.status === 'RESPONSE_EVALUATED' ||
              original?.status === 'RESPONSE_IN_REVIEW'
            ? 'blue'
            : original?.status === 'REQUEST_CANCELLED'
            ? 'red'
            : 'gray'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {original?.status === 'RESPONSE_RECEVIED'
                  ? 'Received'
                  : original?.status === 'AWAITING_RESPONSE'
                  ? 'Awaiting'
                  : original?.status === 'RESPONSE_IN_REVIEW'
                  ? 'In Review'
                  : original?.status === 'RESPONSE_EVALUATED'
                  ? 'Evaluated'
                  : original?.status === 'REQUEST_DECLINED'
                  ? 'Declined'
                  : original?.status === 'REQUEST_CANCELLED'
                  ? 'Cancelled'
                  : 'Not Sent'}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[50px] lg:w[60px] w-[60px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='relative'>
            <button
              className={
                'flex items-center py-2 px-4 text-Gray-500 hover:text-Gray-900'
              }
              onClick={() => {
                setSelectedResponse(original)
                setIsDownlaodAssessmentReport(true)
              }}>
              <i
                className={
                  'ri-download-2-line flex items-center text-heading-5 mr-2'
                }
              />
            </button>
          </div>
        )
      },
    },
  ]
  return COLUMNS
}
